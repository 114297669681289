@import "antd/dist/reset.css";
@import "./font-style.css";
@import "./select.css";
@import "./date-range-picker.css";
@import "pages/eod-management/components/drawer.css";
@import "pages/eod-management/edo-global-style.css";
@import "pages/email-management/email-management-global-style.css";
@import "pages/discharge-list-management/discharge-management-global-style.css";

* {
  padding: 0;
  margin: 0;
}

*,
::before,
::after {
  border-width: 0;
}

body {
  overflow: hidden;
}

.ant-menu .ant-menu-submenu-arrow::after,
.ant-menu-submenu-arrow::before {
  background-color: theme("colors.blue.sapphire") !important;
}

.sidebar-custom-submenu .ant-menu-submenu-title {
  width: theme("width.full");
  border-radius: theme("borderRadius.none");
  margin: theme("margin.0") !important;
}

.sidebar-custom-submenu .ant-menu-submenu-title:hover,
.sidebar-custom-item.ant-menu-item:not(.ant-menu-item-selected):hover,
.sidebar-custom-item.ant-menu-item-selected:hover {
  background-color: theme("colors.blue.solitude-light") !important;
}

.sidebar-custom-submenu .ant-menu-submenu-title {
  padding: 0 theme("spacing.6") !important;
  padding-inline-end: none !important;
}

.custom-dropdown-menu.ant-dropdown-menu .ant-dropdown-menu-item:hover,
.sidebar-custom-item.ant-menu-item:not(.ant-menu-item-selected),
.sidebar-custom-item.ant-menu-item-selected {
  background-color: theme("colors.white");
}

.sidebar-custom-item.ant-menu-item-selected:hover
  > .ant-menu-title-content
  > a {
  color: theme("colors.red.free-speech") !important;
}

.custom-table-customer-management .ant-table-column-sorters > span {
  margin-right: 10px;
}

.custom-table-customer-management tbody tr td {
  border-bottom: 1px solid theme("colors.blue.hawkes-blue") !important;
}

.custom-table-customer-management thead tr th {
  border-bottom: 1px solid theme("colors.blue.sapphire") !important;
}

.custom-table-customer-management .ant-checkbox-inner {
  border-radius: 0px !important;
}

.custom-table-customer-management .ant-checkbox-checked .ant-checkbox-inner {
  background-color: theme("colors.blue.sapphire");
  border-color: theme("colors.blue.sapphire");
}

.custom-table-customer-management
  .ant-checkbox-indeterminate
  .ant-checkbox-inner:after {
  background-color: theme("colors.blue.sapphire") !important;
  border-color: theme("colors.blue.solitude") !important;
}
.custom-child-table-edo-import .ant-table-content thead tr th {
  color: theme("colors.blue.sapphire") !important;
  font-family: "Roboto" !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 18px !important;
}
.custom-table-customer-management
  .ant-checkbox-indeterminate
  .ant-checkbox-inner {
  background-color: #ffffff !important;
  border-color: theme("colors.blue.solitude") !important;
}

.custom-table-customer-management
  .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-inner
  .custom-table-customer-management
  .ant-checkbox:not(.ant-checkbox-disabled):hover
  .ant-checkbox-inner,
.custom-table-customer-management
  .ant-checkbox:not(.ant-checkbox-checked):hover
  .ant-checkbox-inner {
  border-color: theme("colors.blue.solitude") !important;
}

.custom-table-customer-management
  .ant-checkbox-wrapper-checked:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-inner,
.ant-checkbox-checked:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  background-color: theme("colors.blue.sapphire") !important;
}

.custom-pagination-customer-management .ant-pagination .ant-pagination-item a {
  color: theme("colors.blue.sapphire");
  font-family: "Roboto";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.custom-pagination-customer-management
  .ant-pagination
  .ant-pagination-item-active {
  border: none;
  border-radius: 6px;
  background-color: theme("colors.blue.solitude-second");
}
.custom-pagination-customer-management
  .ant-pagination
  .ant-pagination-item-active
  a {
  color: theme("colors.blue.sapphire");
  font-family: "Roboto";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}

.custom-input-customer-management .ant-input-affix-wrapper .ant-input-prefix {
  margin-inline-end: 8px;
}

/* date picker calendar popup global css */
.ant-picker-panel-layout {
  border-radius: 6px !important;
  border: 1px solid theme("colors.blue.solitude") !important;
  box-shadow: 3px 2px 21px 2px rgba(0, 0, 0, 0.13) !important;
}

.ant-picker-presets > ul {
  border-right: 1px solid theme("colors.blue.solitude") !important;
  font-family: "Roboto";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.ant-picker-header {
  border: none !important;
  color: theme("colors.blue.sapphire") !important;
}

.ant-picker-body {
  color: theme("colors.blue.sapphire") !important;
}

.ant-picker-cell {
  color: theme("colors.blue.solitude") !important;
}

.ant-picker-cell-in-view {
  color: theme("colors.blue.sapphire") !important;
}

.custom-rangePicker-customer-management
  .ant-picker-range
  .ant-picker-range-separator,
.ant-picker-header-super-prev-btn,
.ant-picker-header-super-next-btn {
  display: none;
}

/*style days of week of calendar, consider as global */
.ant-picker-footer-extra {
  border-bottom: none !important;
}

.ant-picker-dropdown .ant-picker-panel-container .ant-picker-presets ul li {
  border-radius: 0 !important;
}

.ant-picker-dropdown
  .ant-picker-panel-container
  .ant-picker-presets
  ul
  li:hover {
  background-color: theme("colors.blue.sapphire-light");
}

.ant-picker-dropdown
  .ant-picker-panel-container
  .ant-picker-presets
  ul
  li:hover
  > span {
  color: white !important;
}

.ant-picker-dropdown .ant-picker-panel-container .ant-picker-presets {
  color: theme("colors.blue.midnight-express") !important;
}

.ant-picker-panels {
  position: relative;
  width: 100%;
}

:root {
  --date-picker-visibility-custom: "visible";
  --date-picker-dual-single-style-position-left: 138px;
}

.ant-picker-footer-extra {
  position: absolute;
  top: 48px;
  left: var(--date-picker-dual-single-style-position-left);
  color: #596591 !important;
  width: 77%;
  display: flex;
  justify-content: space-between;
  visibility: var(--date-picker-visibility-custom);
}

.ant-picker-content thead th {
  color: theme("colors.blue.waikawa-grey") !important;
  visibility: hidden;
}

.ant-picker-month-btn {
  /* color: red !important; */
}
.ant-picker-month-btn::after {
  /* content: "d"; */
}

.custom-table-customer-management thead tr th {
  border-bottom: 1px solid theme("colors.blue.sapphire") !important;
}

.custom-table-customer-management thead tr th::before {
  display: none !important;
}

.ant-picker-header-prev-btn {
  padding-left: 25px !important;
}

.ant-picker-header-next-btn {
  padding-right: 25px !important;
}

.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid theme("colors.blue.sapphire") !important;
  border-radius: 0px !important;
}

.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-end
  .ant-picker-cell-inner {
  background: var(--primary-text-shape, #0a2071) !important;
  border-radius: 0px !important;
}

/* end date picker calendar popup global css */

.custom-customer-modal-customer-management .ant-form-item-required {
  color: theme("colors.blue.sapphire") !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  font-family: "Roboto";
}

.custom-confirmation-modal-customer-management
  .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: theme("colors.red.base");
}
.custom-customer-modal-customer-management
  .ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  background-color: theme("colors.red.base") !important;
}

/* global ant notificaiton + alert message */
.ant-notification .ant-notification-notice .ant-notification-notice-message {
  margin-bottom: 0px;
}

.ant-notification .ant-notification-notice .ant-notification-notice-close {
  top: 16px;
}

.custom-alert .ant-alert-success {
  color: theme("colors.blue.sapphire") !important;
  font-family: "Roboto";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  background-color: theme("colors.green.jagged-ice") !important;
  border: none !important;
  height: 52px !important;
}

.custom-alert .ant-alert-info {
  color: theme("colors.blue.sapphire") !important;
  font-family: "Roboto";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  background-color: theme("colors.green.jagged-ice") !important;
  border: none !important;
  height: 52px !important;
}

.custom-alert .ant-alert-error {
  /* color: theme("colors.blue.sapphire") !important; */
  font-family: "Roboto";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  /* background-color: theme("colors.grey.slate-grey") !important; */
  border: none !important;
  height: 52px !important;
}

.custom-alert .ant-alert-success .ant-alert-icon {
  color: #00865d !important;
}

/* end global ant notificaiton + alert message */
.custom-breadcrumb-link:hover {
  background-color: transparent !important;
}

/* custom modal style */
.custom-modal-style .ant-modal-content {
  padding: theme("spacing.6");
}

.custom-modal-style .ant-modal-footer {
  margin-top: 0 !important;
}

.custom-modal-style
  .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: theme("colors.red.base");
}

/* radio custom style */
.ant-radio-wrapper .ant-radio-inner::after {
  background-color: #0a2071 !important;
  transform: scale(0.5) !important;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #0a2071 !important;
  border-width: 1.5px !important;
}

.ant-radio-checked .ant-radio-inner {
  background-color: white !important;
}

.customInputPlaceHolder > input::placeholder {
  color: theme("colors.blue.rock-blue");
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

/* tags */

.customeTags:hover {
  background: theme("colors.blue.sapphire") !important;
  color: theme("colors.sWhite.base") !important;
}

.customeTags {
  background: theme("colors.sWhite.base") !important;
  color: theme("colors.blue.sapphire") !important;
}

/* end tags */
.underLineHover:hover {
  text-decoration: underline;
}

.borderOverRide > button {
  border: none !important;
}
